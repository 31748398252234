/* Custom Calendar Styling */
.custom-calendar {
    border: 1px solid #e2e8f0; /* Light gray border */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }
  
  .custom-calendar .react-datepicker__header {
    background-color: #f8fafc; /* Light background for header */
    border-bottom: 1px solid #e2e8f0; /* Border under the header */
    padding: 10px;
  }
  
  .custom-calendar .react-datepicker__current-month {
    font-family: 'Quicksand, sans-serif';
    font-size: 16px;
    font-weight: 600;
    color: #1f2937; /* Dark text */
    margin-bottom: 10px;
  }
  
  .custom-calendar .react-datepicker__day-name,
  .custom-calendar .react-datepicker__day {
    font-family: 'Quicksand, sans-serif';
    width: 2.5rem;
    line-height: 2.5rem;
    font-size: 14px;
    font-weight: 500;
    color: #374151; /* Dark gray text */
    border-radius: 50%; /* Rounded day cells */
  }
  
  .custom-calendar .react-datepicker__day:hover {
    background-color: #e5e7eb; /* Highlight on hover */
    border-radius: 50%;
  }
  
  .custom-calendar .react-datepicker__day--selected {
    background-color: #3b82f6; /* Blue for selected day */
    color: #fff; /* White text for contrast */
  }
  
  .custom-calendar .react-datepicker__day--today {
    border: 1px solid #3b82f6; /* Blue border for today */
    color: #3b82f6; /* Blue text for today */
    font-weight: 600;
  }

  /* Dropdown Menu */
.custom-calendar .react-datepicker__month-dropdown,
.custom-calendar .react-datepicker__year-dropdown {
  background-color: #ffffff; 
  border: 1px solid #e2e8f0; /* Light gray border */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  overflow: hidden;
  padding: 5px 0; /* Add spacing inside the dropdown */
  z-index: 10; /* Ensure dropdown is above other elements */
}

/* Dropdown Item */
.custom-calendar .react-datepicker__month-option,
.custom-calendar .react-datepicker__year-option {
  padding: 8px 12px; /* Add spacing for dropdown items */
  font-size: 14px;
  color: #374151; /* Dark gray text */
  cursor: pointer;
  transition: 0.2s;
}

/* Hover Effect */
.custom-calendar .react-datepicker__month-option:hover,
.custom-calendar .react-datepicker__year-option:hover {
  background-color: #f3f4f6; /* Light hover effect */
  color: #1f2937; /* Darker text on hover */
}

/* Selected Item */
.custom-calendar .react-datepicker__month-option--selected,
.custom-calendar .react-datepicker__year-option--selected {
  background-color: #3b82f6; /* Blue for selected item */
  color: #ffffff; /* White text for selected item */
  font-weight: bold;
}

/* Arrow Buttons */
.custom-calendar .react-datepicker__navigation {
  top: 10px;
}

.custom-calendar .react-datepicker__navigation--previous {
  border-right-color: #3b82f6;
}

.custom-calendar .react-datepicker__navigation--next {
  border-left-color: #3b82f6;
}
  